<template>
<b-form class="form" @submit.stop.prevent="onSubmit">
  <b-container fluid class="p-0">
    <b-row>
      <b-col cols="12" md="8" offset-md="2">
        <b-form-group :label="`${$t('FORM.LOCALE')}`" label-for="input-1" label-cols-md="2" label-align-md="right">
          <InputForm
            id="input-1"
            v-model="$v.form.locale.$model"
            :state="validateState('locale')"
            :placeholder="$t('FORM.LOCALE')"
            trim
            @input="clearServerError('locale')"
            aria-describedby="input-1-feedback"
          />
          <b-form-invalid-feedback id="input-1-feedback">
            <template v-if="serverErrors.name">{{ serverErrors.locale[0] }}</template>
            <template v-else>{{ $t('VALIDATION.REQUIRED', {name: $t('FORM.LOCALE')}) }}</template>
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group :label="`${$t('FORM.KEY')}`" label-for="input-2" label-cols-md="2" label-align-md="right">
          <InputForm
            id="input-2"
            v-model="$v.form.key.$model"
            :state="validateState('key')"
            :placeholder="$t('FORM.KEY')"
            trim
            @input="clearServerError('key')"
            aria-describedby="input-1-feedback"
          />
          <b-form-invalid-feedback id="input-1-feedback">
            <template v-if="serverErrors.key">{{ serverErrors.key[0] }}</template>
            <template v-else>{{ $t('VALIDATION.REQUIRED', {name: $t('FORM.KEY')}) }}</template>
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group :label="`${$t('FORM.TEXT')}`" label-for="input-3" label-cols-md="2" label-align-md="right">
          <InputForm
            id="input-3"
            v-model="$v.form.text.$model"
            :state="validateState('text')"
            :placeholder="$t('FORM.TEXT')"
            trim
            @input="clearServerError('text')"
            aria-describedby="input-1-feedback"
          />
          <b-form-invalid-feedback id="input-1-feedback">
            <template v-if="serverErrors.text">{{ serverErrors.text[0] }}</template>
            <template v-else>{{ $t('VALIDATION.REQUIRED', {name: $t('FORM.TEXT')}) }}</template>
          </b-form-invalid-feedback>
        </b-form-group>
        <div class="text-right">
          <b-button :disabled="$loading || $v.form.$anyError" type="submit" variant="primary" size="sm">
            <b-spinner v-if="$loading" small variant="light" />
            {{ $t('FORM.SUBMIT') }}
          </b-button>
        </div>
      </b-col>
    </b-row>
  </b-container>
</b-form>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import Repo from '@/core/repository/company/translationsRepository'
import { validationMixin } from 'vuelidate'
import serverVuelidate from '../../../mixins/serverVuelidate'
import InputForm from '../../forms-items/input'
import Entity from '../../../model/translation'

export default {
  name: 'BranchForm',
  components: { InputForm },
  mixins: [validationMixin, serverVuelidate],
  props: {
    entity: {
      type: Entity,
      default: () => new Entity(),
    },
  },
  data() {
    return {
      form: new Entity(),
      validations: {
        form: {
          locale: { required },
          key: { required },
          text: { required },
        },
      },
    }
  },
  watch: {
    entity() {
      Object.assign(this.form, this.entity)
    },
  },
  methods: {
    resetForm() {
      this.form = {
        name: '',
        locale: '',
        text: '',
      }
      this.$nextTick(this.$v.$reset)
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },
    onSubmit() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        return
      }
      this.loadingOn()
      this.clearServerErrors()
      Repo.save(Object.assign(new Entity(), this.form))
        .then(this.resetForm)
        .then(this.$toastSuccess('TOAST.SUCCESS'))
        .then(() => {
          setTimeout(() => this.$router.push({ name: 'companyTranslationsIndex' }), 100)
        })
        .catch((err) => {
          this.$toastDanger('TOAST.ERROR')
          this.seServerErrors(err.response.data)
        })
        .finally(this.loadingOff)
    },
  },
}
</script>

<style scoped>

</style>
