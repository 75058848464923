import EntityRepository from '@/core/repository/entityRepository'
import { typeOf } from '@/helpers/type'
import Translation from '@/model/translation'

export default new class TranslationsRepository extends EntityRepository {
  baseUri() {
    return 'company/translation'
  }

  mapEntity(props, prev = null) {
    if (props == null) {
      return null
    }
    let ent = prev
    if (!typeOf(ent, Translation)) {
      ent = new Translation()
    }
    ent.uuid = props.uuid || ent.uuid
    ent.locale = props.locale || ent.locale
    ent.key = props.key || ent.key
    ent.text = props.text || ent.text

    return ent
  }
}()
