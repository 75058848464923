import Entity from './entity'

export default class Translation extends Entity {
  /** @type String */
  uuid
  /** @type String */
  locale
  /** @type String */
  key
  /** @type String */
  text

  persistentProps() {
    return {
      locale: this.locale,
      key: this.key,
      text: this.text,
    }
  }
}
